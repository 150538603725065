@-webkit-keyframes scroller {
  0% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%)
  }
  15% {
      -webkit-transform: translateX(-105%);
      transform: translateX(-105%)
  }
  100% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%)
  }
}
@keyframes scroller {
  0% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%)
  }
  15% {
      -webkit-transform: translateX(-105%);
      transform: translateX(-105%)
  }
  100% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%)
  }
}
@-webkit-keyframes scrollerdown {
  0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%)
  }
  15% {
      -webkit-transform: translateY(105%);
      transform: translateY(105%)
  }
  100% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%)
  }
}
@keyframes scrollerdown {
  0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%)
  }
  15% {
      -webkit-transform: translateY(105%);
      transform: translateY(105%)
  }
  100% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%)
  }
}
@-webkit-keyframes trup {
  0% {
      -webkit-transform: translateY(150px);
      transform: translateY(150px)
  }
  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}
@keyframes trup {
  0% {
      -webkit-transform: translateY(150px);
      transform: translateY(150px)
  }
  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}
@-webkit-keyframes trdown {
  0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px)
  }
  100% {
      -webkit-transform: translateY(150px);
      transform: translateY(150px)
  }
}
@keyframes trdown {
  0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px)
  }
  100% {
      -webkit-transform: translateY(150px);
      transform: translateY(150px)
  }
}
@-webkit-keyframes loadingBlink{
  0%{
      opacity:0
  }
  100%{
      opacity:1
  }
}
@keyframes loadingBlink{
  0%{
      opacity:0
  }
  100%{
      opacity:1
  }
}
@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
