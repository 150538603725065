.loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  overflow: hidden;
}
.loading-wrapper .loading-inner {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  background-color: rgba(15,115,238,.7);
  width: 100%;
  height: 100%;
}
.loading-back {
  display: block;
  background-color: none;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.loading-wrapper .loading-cont {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}
.loading-wrapper .loading-cont .img_wrap{
  width: 200px;
  display: block;
  margin: 0 auto;
  overflow: hidden;
}
.loading-wrapper .loading-cont .img_wrap img{
  background: #fff;
  padding:5px 10px;
}
.loading-wrapper .loading-cont p,.loader_wrap{
  color: #fff;
  font-size: 16px;
  margin-top: 0em;
  text-align: center;
  overflow: hidden;
  font-weight: 500;
}
.loader_wrap{
  width: 100%;
}
.loading-back,.loading-inner{
  -webkit-transition-timing-function: cubic-bezier(0.21, 0.68, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.21, 0.68, 0.45, 0.94);
  -webkit-transition-duration: 1.24s;
  transition-duration: 1.24s;
}
.loading-cont span img {
  -webkit-transform: translateY(150px);
  transform: translateY(150px);
  -webkit-animation: trup 2s cubic-bezier(0.67, 0.05, 0.32, 1) -0.1s 1;
  animation: trup 2s cubic-bezier(0.67, 0.05, 0.32, 1) -0.1s 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.loading-cont p .span_txt,.loader_wrap .loader_inner{
  -webkit-transform: translateY(150px);
  transform: translateY(150px);
  -webkit-animation: trup 2s cubic-bezier(0.67, 0.05, 0.32, 1) 0s 1;
  animation: trup 2s cubic-bezier(0.67, 0.05, 0.32, 1) 0s 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  display: inline-block;
}
.loading-inner {
  position: relative;
  z-index: 2;
}
.js-load .loading-back,
.js-load .loading-inner {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
  -webkit-transition-timing-function: cubic-bezier(0.67, 0.05, 0.32, 1);
  transition-timing-function: cubic-bezier(0.67, 0.05, 0.32, 1);
}
.js-load-act .sh_txt .span_txt,.js-load-act .loader_inner{
  -webkit-animation: trdown 1s cubic-bezier(0.67, 0.05, 0.32, 1) 0s 1;
  animation: trdown 1s cubic-bezier(0.67, 0.05, 0.32, 1) 0s 1;
}
.loading-wrapper.js-load-end {
  display: none;
}
html{
  overflow: hidden;
}
html.js-load-end {
  overflow-y: scroll;
}
.js-fixed:after {
  position: fixed;
}
.loading-cont .sh_txt .span_txt .dot_text span{
  -webkit-animation: loadingBlink 0.5s linear infinite;
  animation: loadingBlink 0.5s linear infinite;
  display: inline-block;
  font-size: 24px;
}
.loading-cont .sh_txt .span_txt .dot_text span:nth-child(2){
  -webkit-animation: loadingBlink 0.15s 0.5s linear infinite;
  animation: loadingBlink 0.15s 0.5s linear infinite;
}
.loading-cont .sh_txt .span_txt .dot_text span:nth-child(3){
  -webkit-animation: loadingBlink 0.3s 0.5s linear infinite;
  animation: loadingBlink 0.3s 0.5s linear infinite;
}
.loader,
.loader:after {
border-radius: 50%;
width: 50px;
height: 50px;
}
.loader {
margin: 20px auto;
font-size: 10px;
position: relative;
text-indent: -9999em;
border-top: 1.1em solid rgba(255, 255, 255, 0.4);
border-right: 1.1em solid rgba(255, 255, 255, 0.4);
border-bottom: 1.1em solid rgba(255, 255, 255, 0.4);
border-left: 1.1em solid #ffffff;
-webkit-transform: translateZ(0);
-ms-transform: translateZ(0);
transform: translateZ(0);
-webkit-animation: loader 1.1s infinite linear;
animation: loader 1.1s infinite linear;
height: 50px;
}