/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/


html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}
 
body {
    line-height:1;	
	/*-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
				box-sizing: border-box;*/
}
 
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
    display:block;
}
 
nav ul {
    list-style:none;
}
 
blockquote, q {
    quotes:none;
}
 
blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}
 
a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align: middle;
    background:transparent;
    outline:none;
    text-decoration:none;
    display: block;
	color: #2f1b00;
}
a img{
	/*vertical-align:bottom;*/
}
li{
    list-style:none;
}
h3,h4,h5,h6{
    font-weight:500;
} 
/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
}
 
/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:#000; 
    font-style:italic;
    font-weight:bold;
}
 
del {
    text-decoration: line-through;
}
 
abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}
 
table {
    border-collapse:collapse;
    border-spacing:0;
}
 
/* change border colour to suit your needs */
hr {
    display:block;
    height:1px;
    border:0;   
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}
*{
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;
	-o-box-sizing:border-box;
	box-sizing:border-box;
}
input, select {
    vertical-align:middle;
}
select {
	-webkit-appearance: none;
	appearance: none;
	border: none;
	border-radius: 0;
}
/* clear */
.clear:after{
	content:" ";
	display:block;
	height:0;
	clear:both;
	line-height:0;
	visibility:hidden;
}

img{
	display: block;
	width:100%;
	height:auto;
}
button{
	border: none;
	background: none;
}
@media screen and (min-width:769px){
	.pc{
		display:block!important;
	}
	.i_pc{
		display:inline;
	}
	br.pc{
		display:inline;
	}
	.sp{
		display:none!important;
	}	
	.sp_i{
		display:none;
	}

	br.sp{
		display:none;
	}
	a,input[type="button"],button {
		-webkit-transition: 0.3s;
		-ms-transition: 0.3s;
		transition: 0.3s;
		cursor: pointer;
	}
	a:hover,input[type="button"]:hover,button:hover{
		opacity: 0.7;
	}
}
@media screen and (max-width:768px){
	img{
		width:100%;
		height:auto;
	}
	.pc{
		display:none!important;
	}
	.i_pc{
		display:none;
	}
	br.pc{
		display:none;
	}
	.sp{
		display:block!important;
	}
	.sp_i{
		display:inline!important;
	}
	br.sp{
		display:inline;
	}
	.clear_sp:after{
		content:" ";
		display:block;
		height:0;
		clear:both;
		line-height:0;
		visibility:hidden;
	}

}
