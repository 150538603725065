@charset "UTF-8";
/* CSS Document */
body {
  color: #222;
  font-family: 'Noto Sans JP',"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  width: 100%;
  -webkit-text-size-adjust: 100%;
  letter-spacing: 0.1em;
  font-weight: 400;
}

html {
  overflow-y: scroll;
}

.wrapper {
  overflow: hidden;
}

.inner {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.biginner {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.dfb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.dpt {
  display: table;
}

.sp_ib {
  display: none;
}

select, input, button {
  font-family: 'Noto Sans JP',"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
}

.no_pb {
  padding-bottom: 0 !important;
}

.more {
  text-align: center;
  color: #0F73EE;
  background: #fff;
  border-radius: 50px;
  border: solid 1px #0F73EE;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 10px;
}

/**** 共通パーツ ****/
/***** header navi *****/
#header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9000;
  /***** headerの検索ボックス *****/
}

#header .header_menu {
  background: #F1F4FC;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.06);
          box-shadow: 0 4px 5px rgba(0, 0, 0, 0.06);
  padding: 15px 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#header .header_menu .search {
  margin-right: 20px;
}

#header .header_menu .search img {
  display: inline-block;
  vertical-align: middle;
}

#header .header_menu .hamburger {
  width: 26px;
  position: relative;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
}

#header .header_menu .hamburger:hover {
  opacity: .7;
}

#header .header_menu .hamburger span {
  width: 26px;
  height: 3px;
  display: block;
  background: #0F73EE;
  border-radius: 20px;
  position: absolute;
}

#header .header_menu .hamburger span:nth-child(1) {
  top: 1px;
  -webkit-transition: .5s;
  transition: .5s;
  -webkit-transform-origin: left;
          transform-origin: left;
}

#header .header_menu .hamburger span:nth-child(2) {
  top: 9px;
  -webkit-transition: .5s;
  transition: .5s;
}

#header .header_menu .hamburger span:nth-child(3) {
  bottom: 1px;
  -webkit-transition: .5s;
  transition: .5s;
  -webkit-transform-origin: left;
          transform-origin: left;
}

#header .header_menu .hamburger.open span:nth-child(1) {
  top: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 26px;
}

#header .header_menu .hamburger.open span:nth-child(2) {
  top: 8px;
  width: 0;
  opacity: 0;
}

#header .header_menu .hamburger.open span:nth-child(3) {
  bottom: 0px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 26px;
}

#header .menu {
  background: #0F73EE;
  display: 'none';
}

#header .menu .dfb {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 18px 30px;
}

#header .menu .dfb li {
  border-right: solid 1px #fff;
}

#header .menu .dfb li:last-child {
  border-right: none;
}

#header .menu .dfb li:last-child a {
  padding-right: 0;
}

#header .menu .dfb li a {
  color: #fff;
  padding: 0 25px;
}

#header .header_search_box {
  background: rgba(0, 60, 185, 0.91);
  height: calc(100vh - 60px);
  position: absolute;
  width: 100%;
  top: 60px;
}

#header .header_search_box .search_box {
  position: absolute;
  top: calc(50%);
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

#header .header_search_box .search_box .search_box_inner {
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

#header .header_search_box .search_box .search_close {
  float: right;
}

#header .header_search_box .search_box .search_close img {
  width: 40px;
  margin-top: 10px;
  cursor: pointer;
  display: inline-block;
}

.search_box {
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}

.search_box .search_box_inner {
  -webkit-box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.2);
          box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.2);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 15px;
  background: #F8FAFF;
}

.search_box .search_box_inner h2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 30px;
}

.search_box .search_box_inner ul li {
  margin-bottom: 20px;
}

.search_box .search_box_inner ul li p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.search_box .search_box_inner ul li input {
  font-size: 14px;
  width: 100%;
  background: #fff;
  border: solid 1px #0F73EE;
  border-radius: 5px;
  padding: 8px 11px;
  font-weight: 400;
  letter-spacing: 0.1em;
}

.search_box .search_box_inner ul li input::-webkit-input-placeholder {
  color: #C9C9C9;
}

.search_box .search_box_inner ul li input:-ms-input-placeholder {
  color: #C9C9C9;
}

.search_box .search_box_inner ul li input::-ms-input-placeholder {
  color: #C9C9C9;
}

.search_box .search_box_inner ul li input::placeholder {
  color: #C9C9C9;
}

.search_box .search_box_inner ul li .select_wrap {
  position: relative;
}

.select_wrap_double {
  display: flex;
  align-items: center;
}

.select_wrap_double > .select_wrap {
  width: 125px;
}

/*年月日*/
.select_wrap_date{
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: .5em;
}

.select_wrap_date > .select_wrap {
  width: auto;
}

.search_box .search_box_inner ul li .select_wrap::after {
  content: "";
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0px;
  width: 0px;
  height: 0px;
  margin: auto;
  border-style: solid;
  border-color: #0F73EE transparent transparent transparent;
  border-width: 4px 3px 0 3px;
}

.search_box .search_box_inner ul li .select_wrap.disabled::after {
  border-color: #C9C9C9 transparent transparent transparent;
}

.search_box .search_box_inner ul li .select_wrap select {
  font-size: 14px;
  width: 100%;
  background: #fff;
  border: solid 1px #0F73EE;
  border-radius: 5px;
  padding: 8px 1.5em 8px 11px;
  font-weight: 400;
  letter-spacing: 0.1em;
}

.search_box .search_box_inner ul li .select_wrap select:disabled {
  border: solid 1px #C9C9C9;
  color: #C9C9C9;
}

.search_box .search_box_inner ul li.check_box ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.search_box .search_box_inner ul li.check_box ul li {
  margin: 0 5px 5px;
}

.search_box .search_box_inner ul li.check_box ul li p {
  margin-bottom: 10px;
}

.search_box .search_box_inner ul li.check_box ul li input {
  display: inline;
  width: inherit;
  vertical-align: initial;
}

.search_box .search_box_inner ul li.check_box ul li label {
  font-size: 15px;
  padding-left: 2px;
}

.search_box .search_box_inner ul li.radio_btn ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.search_box .search_box_inner ul li.radio_btn ul li {
  margin: 0 5px 5px;
}

.search_box .search_box_inner ul li.radio_btn ul li p {
  margin-bottom: 10px;
}

.search_box .search_box_inner ul li.radio_btn ul li input {
  display: inline;
  width: inherit;
  vertical-align: initial;
}

.search_box .search_box_inner ul li.radio_btn ul li label {
  font-size: 15px;
  padding-left: 2px;
}

.search_box .search_box_inner .search_btn {
  text-align: center;
  background: #0F73EE;
  border: solid 1px #083CA9;
  color: #fff;
  padding:8px 15px;
  font-weight: bold;
  width: 100%;
  border-radius: 15px;
  letter-spacing: 0.2em;
  font-size: 14px;
}

.search_box .search_close {
  text-align: right;
}

.search_box .search_close img {
  width: 40px;
  margin-top: 10px;
  cursor: pointer;
  display: inline-block;
}

.link-style-btn {
  cursor: pointer;
  border: none;
  background: none;
  color: #0033cc;
  text-decoration: underline;
  padding-left: 180px;
}

/*** パンくずリスト ***/
.breadcrumbs {
  margin-top: 60px;
  padding: 8px 30px;
  border-bottom: 1px solid #D6D6D6;
}

.breadcrumbs p {
  font-size: 10px;
  font-weight: 400;
}

.breadcrumbs p span {
  vertical-align: bottom;
  display: inline-block;
  margin-right: 3px;
  margin-left: 3px;
  color: #555555;
}

.breadcrumbs p a {
  display: inline-block;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.2em;
  color: #555555;
}

/****　タブ切り替え　****/
.tab_biginner {
  width: 100%;
  max-width: 1120px;
  margin: 30px auto 0;
}

.tab_biginner .tab-area {
  background-color: #fff;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  padding: 5px 0;
}

.tab_biginner .tab-area .btn_wrap {
  position: relative;
}

.tab_biginner .tab-area .btn_wrap button {
  position: absolute;
  width: 45px;
  height: 45px;
  padding: 0;
  background: #F2F2F2;
}

.tab_biginner .tab-area .btn_wrap button.left {
  left: 0;
}

.tab_biginner .tab-area .btn_wrap button.left::before {
  content: '';
  width: 8px;
  height: 8px;
  margin-top: -5px;
  border-top: solid 3px #fff;
  border-left: solid 3px #fff;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  position: absolute;
  top: 50%;
  left: 20px;
}

.tab_biginner .tab-area .btn_wrap button.right {
  right: 0;
}

.tab_biginner .tab-area .btn_wrap button.right::before {
  content: '';
  width: 8px;
  height: 8px;
  margin-top: -5px;
  border-top: solid 3px #fff;
  border-right: solid 3px #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 20px;
}

.tab_biginner .tab-area .btn_wrap.is_active button {
  background: #0F73EE;
}

.tab_biginner .tab-area .tab_wrap {
  width: calc(100% - 100px);
  max-width: 1020px;
  margin: 0 auto;
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE, Edge 対応 */
  scrollbar-width: none;
  /* Firefox 対応 */
}

.tab_biginner .tab-area .tab_wrap::-webkit-scrollbar {
  display: none;
  /* chrome 対応 */
}

.tab_biginner .tab-area .tab_wrap .tab_inner {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.tab_biginner .tab-area .tab_wrap .tab_inner .tab {
  text-align: center;
  font-size: 18px;
  -webkit-transition: .5s;
  transition: .5s;
  color: #222;
  background: #F2F2F2;
  cursor: pointer;
  padding: 13px 15px 14px;
  margin: 0 5px;
  display: inline-block;
  position: relative;
}

.tab_biginner .tab-area .tab_wrap .tab_inner .tab:first-child {
  margin-left: 0;
}

.tab_biginner .tab-area .tab_wrap .tab_inner .tab::after {
  content: " ";
  position: absolute;
  height: 100%;
  width: 1px;
  background: #707070;
  right: -5px;
  top: 0;
}

.tab_biginner .tab-area .tab_wrap .tab_inner .tab:last-child {
  margin-right: 0;
}

.tab_biginner .tab-area .tab_wrap .tab_inner .tab:last-child::after {
  display: none;
}

.tab_biginner .tab-area .tab_wrap .tab_inner .tab.active {
  background-color: #0F73EE;
  color: #fff;
}

/**** tab切り替えコンテンツ ****/
.content-area .content {
  display: none;
}

.content-area .content .ttl_style3 {
  font-size: 24px;
  color: #fff;
  background: #083CA9;
  padding: 10px;
  padding-left: 15px;
  margin-bottom: 20px;
}

.content-area .content .adv {
  margin-bottom: 30px;
}

.content-area .content .content_inner {
  padding: 0 15px 30px;
  margin-bottom: 30px;
}

.content-area .content .content_inner.border_bottom {
  border-bottom: 1px solid #F2F2F2;
}

.content-area .content .content_inner .slide_movie .slide .thumnail {
  margin-top: 5px;
}

.content-area .content .content_inner .slide_movie .slide .thumnail li {
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
  width: 16%;
}

.content-area .content .content_inner .slide_movie .slide .thumnail li:hover {
  opacity: 0.7;
}

.content-area .content .content_inner .slide_movie .movie {
  width: 100%;
  max-width: 360px;
  margin-left: 2%;
}

.content-area .content .content_inner .slide_movie .movie iframe {
  width: 100%;
  height: 100%;
}

.content-area .content .content_inner .info_table_layout li .dpt {
  width: 100%;
}

.content-area .content .content_inner .info_table_layout li .dpt dt, .content-area .content .content_inner .info_table_layout li .dpt dd {
  display: table-cell;
  padding: 14px 16px;
  font-size: 12px;
  line-height: 1.5;
  border: solid 1px #E0E0E0;
  border-bottom: none;
}

.content-area .content .content_inner .info_table_layout li .dpt dt {
  background: #F5F5F5;
  width: 200px;
  font-weight: 500;
  border-right: none;
}

.content-area .content .content_inner .info_table_layout li .dpt dd {
  background: #fff;
  width: calc(100% - 200px);
  border-left: solid 1px #E0E0E0;
  font-weight: 400;
}

.content-area .content .content_inner .info_table_layout li .dpt dd a {
  color: #0F73EE;
}

.content-area .content .content_inner .info_table_layout li .dpt dd a.gmap {
  border: solid 1px #0F73EE;
  border-radius: 100px;
  display: inline-block;
  padding: 0px 12px 2px;
  margin-top: 10px;
  letter-spacing: 0;
}

.content-area .content .content_inner .info_table_layout li .dpt dd a.gmap img {
  width: 8px;
  display: inline-block;
  vertical-align: sub;
  margin-right: 4px;
}

.content-area .content .content_inner .info_table_layout li .dpt dd a.homepage {
  border-bottom: 1px solid #0F73EE;
  display: inline-block;
}

.content-area .content .content_inner .info_table_layout li:first-child dt {
  border-top-left-radius: 5px;
}

.content-area .content .content_inner .info_table_layout li:first-child dd {
  border-top-right-radius: 5px;
}

.content-area .content .content_inner .info_table_layout li:last-child dt {
  border-bottom-left-radius: 5px;
  border-bottom: solid 1px #E0E0E0;
}

.content-area .content .content_inner .info_table_layout li:last-child dd {
  border-bottom-right-radius: 5px;
  border-bottom: solid 1px #E0E0E0;
}

.content-area .content .content_inner .pr {
  padding: 20px;
  background: #F8FAFF;
  border-radius: 5px;
  -webkit-box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.25);
          box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.25);
}

.content-area .content .content_inner .pr h3 {
  margin-bottom: 15px;
}

.content-area .content .content_inner .pr p {
  line-height: 1.5;
  margin-left: 1.5em;
  font-size: 12px;
  letter-spacing: 0.2em;
}

.content-area .content .content_inner .table_layout {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.content-area .content .content_inner .table_layout table {
  margin: 0;
  margin-bottom: 5px;
  border-collapse: collapse;
}

.content-area .content .content_inner .table_layout table tbody tr th {
  padding: 16px 40px;
  background: #F5F5F5;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2em;
  border: solid 1px #E0E0E0;
}

.content-area .content .content_inner .table_layout table tbody tr td {
  padding: 16px;
  border: solid 1px #E0E0E0;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  border-width: 1px;
  line-height: 1.5;
}

.content-area .content .content_inner .scroll-hint-icon {
  top: calc(50% - 34px);
}

.content-area .content .content_inner .scroll-hint.is-right-scrollable, .content-area .content .content_inner .scroll-hint.is-left-scrollable {
  background: none;
}

.content-area .content.blog_info .blog_list_wrap .blog_list {
  margin-bottom: 30px;
}

.content-area .content.blog_info .blog_list_wrap .blog_list h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.content-area .content.blog_info .blog_list_wrap .blog_list .blog_link {
  margin-bottom: 20px;
}

.content-area .content.blog_info .blog_list_wrap .blog_list .blog_link img {
  width: 20px;
  display: inline-block;
  margin-right: 10px;
}

.content-area .content.blog_info .blog_list_wrap .blog_list .blog_link a {
  color: #0F73EE;
  border-bottom: solid 1px #0F73EE;
  display: inline-block;
  vertical-align: bottom;
  letter-spacing: 0;
}

.content-area .content.blog_info .blog_list_wrap .blog_list .blog_article_wrap .blog_article_list .blog_article_link {
  background: #F8FAFF;
  border: solid 1px #E0E0E0;
  border-radius: 5px;
  padding: 16px 10px;
  margin-bottom: 20px;
}

.content-area .content.blog_info .blog_list_wrap .blog_list .blog_article_wrap .blog_article_list .blog_article_link h4 {
  color: #7B7B7B;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.content-area .content.blog_info .blog_list_wrap .blog_list .blog_article_wrap .blog_article_list .blog_article_link h4 .data {
  margin-right: 0.5em;
}

.content-area .content.blog_info .blog_list_wrap .blog_list .blog_article_wrap .blog_article_list .blog_article_link p {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.5;
}

.content-area .content.show {
  display: block;
}

/**** コンテンツ大枠・共通コンテンツのスタイル ****/
.content_wrap {
  max-width: 1120px;
  width: 100%;
  margin: 50px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.content_wrap .ttl_style2 {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  padding-left: 1em;
  margin-bottom: 16px;
}

.content_wrap .ttl_style2::before {
  content: " ";
  position: absolute;
  width: 3px;
  height: 20px;
  background: #0F73EE;
  left: 0.5em;
}

.content_wrap .main_wrap {
  max-width: 100%;
  width: 100%;
  margin-right: 30px;
  overflow: hidden;
}

.content_wrap .sidenav {
  max-width: 320px;
  width: 100%;
}

.content_wrap .sidenav .sidenav_inner {
  padding: 10px;
  border-radius: 5px;
  background: #F8FAFF;
  -webkit-box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.25);
          box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.25);
}

/**** corp_card_layout ****/
.corp_card_layout {
  margin-bottom: 20px;
  border-radius: 5px;
  background: #F8FAFF;
  -webkit-box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.12);
          box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.12);
}

.corp_card_layout .card_lay_inner {
  padding: 16px;
}

.corp_card_layout .card_lay_inner .corp_num {
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #083CA9;
  border: solid 1px #083CA9;
  border-radius: 3px;
  background: #fff;
  display: inline-block;
  padding: 2px 5px;
}

.corp_card_layout .card_lay_inner .close_corp {
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #F94545;
  border: solid 1px #F94545;
  margin-left: 12px;
  border-radius: 3px;
  background: #fff;
  display: inline-block;
  padding: 2px 5px;
}

.corp_card_layout .card_lay_inner .corp_name {
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 1.27;
}

.corp_card_layout .card_lay_inner .icon_wrap p {
  font-size: 12px;
  color: #7B7B7B;
  letter-spacing: 0;
}

.corp_card_layout .card_lay_inner .icon_wrap p img {
  width: 8px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: bottom;
}

.corp_card_layout .card_lay_inner .icon_wrap p.indust {
  margin-top: 4px;
  margin-bottom: 10px;
}

.corp_card_layout .card_lay_inner .dl_wrap {
  border: solid 1px #083CA9;
  border-radius: 2px;
}

.corp_card_layout .card_lay_inner .dl_wrap dl {
  font-size: 12px;
  letter-spacing: 0.2em;
  border-bottom: none;
  width: 100%;
}

.corp_card_layout .card_lay_inner .dl_wrap dl:first-child {
  border-bottom: solid 1px #083CA9;
}

.corp_card_layout .card_lay_inner .dl_wrap dl dt, .corp_card_layout .card_lay_inner .dl_wrap dl dd {
  display: table-cell;
}

.corp_card_layout .card_lay_inner .dl_wrap dl dt {
  width: 50px;
  background: #F6F6F6;
  border-right: solid 1px #083CA9;
  text-align: center;
  padding: 5px 0;
}

.corp_card_layout .card_lay_inner .dl_wrap dl dd {
  width: calc(100% - 50px);
  background: #fff;
  padding-left: 12px;
  letter-spacing: 0.1em;
}

/*** footer ***/
footer {
  margin-top: 50px!important;
  background: #0F73EE!important;
  padding: 16px 30px!important;
  color: #fff!important;
}

footer .dfb {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-flow: row-reverse;
          flex-flow: row-reverse;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

footer .dfb li {
  margin-left: 25px;
  border-left: solid 1px #fff;
}

footer .dfb li a {
  padding: 4px 0;
  padding-left: 25px;
  color: #fff;
  font-size: 14px;
}

footer .dfb li.copy {
  font-size: 12px;
  font-weight: 200;
  border: none;
  margin-left: 0;
  padding: 4px 0;
}

/***** ページ固有のCSS *****/
/***** top *****/
.top .top_mv {
  margin-top: 60px;
  padding-top: 128px;
  padding-bottom: 156px;
  background: url(../img/mv.jpg) no-repeat bottom center;
  background-size: cover;
}

.top .top_mv .ttl_style {
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: #0F73EE;
  letter-spacing: 0.2em;
  margin-bottom: 25px;
}

.top .top_mv .top_search_box {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  font-size: 16px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.21);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.21);
}

.top .top_mv .top_search_box .top_txt {
  border: none;
  background: #F8FAFF;
  outline: none;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 30px;
  padding-right: 65px;
  letter-spacing: 0.1em;
}

.top .top_mv .top_search_box .top_txt::-webkit-input-placeholder {
  color: #8FBDF7;
}

.top .top_mv .top_search_box .top_txt:-ms-input-placeholder {
  color: #8FBDF7;
}

.top .top_mv .top_search_box .top_txt::-ms-input-placeholder {
  color: #8FBDF7;
}

.top .top_mv .top_search_box .top_txt::placeholder {
  color: #8FBDF7;
}

.top .top_mv .top_search_box .select_wrap {
  position: relative;
}

.top .top_mv .top_search_box .select_wrap::after {
  content: "";
  position: absolute;
  right: 7px;
  top: 0;
  bottom: 0px;
  width: 0px;
  height: 0px;
  margin: auto;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  border-width: 4px 3px 0 3px;
}

.top .top_mv .top_search_box .select_wrap .top_select {
  outline: none;
  letter-spacing: 0.1em;
  background: #0F73EE;
  padding-right: 1em;
  border: none;
  color: #fff;
  padding: 13px 15px;
  width: 100px;
}

.top .top_mv .top_search_box .top_search_btn {
  border: none;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 0 20px;
  background: #F8FAFF;
}

.top .top_mv .top_search_box .top_search_btn img {
  width: 20px;
}

.top .top_mv .mvtxt {
  margin: 0 auto;
  margin-top: 14px;
  font-size: 12px;
  color: #0F73EE;
  text-align: center;
  background: #fff;
  display: block;
  width: 340px;
  padding: 0 2px;
  letter-spacing: 0.1em;
  line-height: 1.5;
}

.top .top_search {
  padding: 16px 20px 8px;
  background: #F8FAFF;
  border-radius: 5px;
  -webkit-box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.25);
          box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.25);
}

.top .top_search h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}

.top .top_search h2 img {
  width: 12px;
  display: inline;
  vertical-align: top;
  margin-right: 6px;
}

.top .top_search ul {
  border-top: 1px solid #F2F2F2;
  padding: 16px 10px;
  padding-bottom: 0;
}

.top .top_search.pre_search {
  margin-bottom: 30px;
}

.top .top_search.pre_search ul {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.top .top_search.pre_search ul li {
  margin-right: 10px;
  margin-bottom: 16px;
}

.top .top_search.pre_search ul li:last-child {
  margin-right: 0;
}

.top .top_search.pre_search ul li a {
  color: #0F73EE;
  background: #fff;
  border-radius: 50px;
  border: solid 1px #0F73EE;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 10px;
}

.top .top_search.indust_search {
  margin-bottom: 30px;
}

.top .top_search.indust_search ul {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.top .top_search.indust_search ul li {
  margin-right: 10px;
  margin-bottom: 16px;
}

.top .top_search.indust_search ul li:last-child {
  margin-right: 0;
}

.top .top_search.indust_search ul li a {
  color: #0F73EE;
  background: #fff;
  border: solid 1px #0F73EE;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 10px;
  padding-right: 26px;
  position: relative;
}

.top .top_search.indust_search ul li a::before {
  content: " ";
  position: absolute;
  font-weight: 500;
  color: #0F73EE;
  top: 11px;
  right: 14px;
  width: 1px;
  height: 6px;
  background: #0F73EE;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.top .top_search.indust_search ul li a::after {
  content: " ";
  position: absolute;
  font-weight: 500;
  color: #0F73EE;
  top: 15px;
  right: 14px;
  width: 1px;
  height: 6px;
  background: #0F73EE;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.top .top_corp_card {
  border-top: 1px solid #DEDEDE;
  padding-top: 30px;
  margin-bottom: 30px;
}

.top .top_corp_card.bankruptcy {
  margin-bottom: 0;
}

.top .top_corp_card .dfb {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.top .top_corp_card .dfb li {
  width: 50%;
  max-width: 375px;
}

.top .top_corp_card .dfb li:nth-child(odd) {
  margin-right: 20px;
}

/****　corporate　****/
.under_mv {
  background: #F8FAFF;
  padding: 30px 0;
}

.corporate .corp_state {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 12px;
}

.corporate .corp_state p {
  font-size: 10px;
  letter-spacing: 0.05em;
  padding: 3px;
  border-radius: 2px;
  background: #fff;
}

.corporate .corp_state .corp_num {
  color: #083CA9;
  border: solid 1px #083CA9;
}

.corporate .corp_state .close_corp {
  color: #F94545;
  border: solid 1px #F94545;
  margin-left: 12px;
}

.corporate .ttl {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.corporate .ttl h1 {
  font-size: 28px;
  letter-spacing: 0.1em;
  width: calc(100% - 160px);
}

.corporate .ttl .fav_button {
  width: 160px;
  font-size: 14px;
  position: relative;
  padding: 10px;
  padding-right: 16px;
  padding-left: 40px;
  color: #707070;
  background: #fff;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  letter-spacing: 0.05em;
  -webkit-transition: .4s;
  transition: .4s;
}

.corporate .ttl .fav_button:hover {
  opacity: 1.0;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
}

.corporate .ttl .fav_button::before {
  content: "★";
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  font-size: 20px;
  -webkit-transition: .4s;
  transition: .4s;
}

.corporate .ttl .fav_button.on {
  background: #FFF941;
  color: #3A3939;
}

.corporate .ttl .fav_button.on::before {
  color: #0F73EE;
}

.corporate .icon_wrap p {
  color: #666666;
  font-size: 14px;
}

.corporate .icon_wrap p.add {
  margin-top: 7px;
  margin-bottom: 3px;
}

.corporate .icon_wrap p img {
  width: 10px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: sub;
}

.corporate .info {
  margin-top: 12px;
}

.corporate .info dl {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 10px;
}

.corporate .info dl:last-child {
  margin-bottom: 0;
}

.corporate .info dl dt {
  border-radius: 5px;
  background: #083CA9;
  text-align: center;
  color: #fff;
  width: 73px;
  font-size: 12px;
  font-weight: 300;
  padding: 3px 0 5px;
  margin-right: 11px;
  letter-spacing: 0.1em;
}

.corporate .info dl dd {
  width: calc(100% - 73px);
  font-size: 14px;
  line-height: 1.3;
}

/*** mypage ***/
.mypage .ttl h2 {
  font-size: 28px;
  font-weight: 700;
}

.mypage .ttl h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
}

.mypage .corp_card_layout .del_btn {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  background: #0F73EE;
  padding: 14px;
  border-radius: 5px;
  font-weight: 500;
  margin-top: 16px;
}

.mypage .add_to .txt_input {
  width: 100%;
  border: solid 1px #E0E0E0;
  border-radius: 5px;
  padding: 15px;
  display: block;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 16px;
}

.mypage .add_to .txt_input::-webkit-input-placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #979797;
}

.mypage .add_to .txt_input:-ms-input-placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #979797;
}

.mypage .add_to .txt_input::-ms-input-placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #979797;
}

.mypage .add_to .txt_input::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #979797;
}

.mypage .add_to .regist_btn {
  max-width: 200px;
  width: 100%;
  background: #0F73EE;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 15px;
  font-size: 14px;
  border-radius: 5px;
}

.mypage #dragDropArea {
  background: #F8FAFF;
  margin-bottom: 30px;
  padding: 20px;
  padding-bottom: 140px;
  border: #C4C4C4 dashed 3px;
  text-align: center;
}

.mypage #dragDropArea p.drag-drop-info {
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  line-height: 1.65;
  color: #C4C4C4;
}

.mypage #dragDropArea p.drag-drop-buttons {
  font-size: 14px;
  color: #A1A1A1;
  text-align: left;
}

.mypage #dragDropArea p.drag-drop-buttons #fileInput {
  border-radius: 0;
}

.mypage #dragDropArea .drag-drop-buttons {
  margin-top: 20px;
  font-size: 12px;
  font-size: 1.2em;
}

.mypage #dragDropArea .drag-drop-buttons input {
  margin: auto;
}

.mypage #dragDropArea #previewArea img {
  width: 50%;
  margin-top: 10px;
}

.mypage #dragDropArea #previewArea .img_del_btn {
  text-align: center;
  color: #fff;
  background: #F94545;
  display: block;
  border-radius: 5px;
  padding: 6px 0;
  letter-spacing: 0.1em;
  font-size: 12px;
  font-weight: 400;
  width: 70px;
  margin-top: 30px;
}

.mypage .blog_setting .statement {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.05em;
}

.mypage .blog_setting .blog_set_wrap {
  margin-top: 30px;
}

.mypage .blog_setting .blog_set_wrap input, .mypage .blog_setting .blog_set_wrap button {
  display: block;
  width: 100%;
}

.mypage .blog_setting .blog_set_wrap button {
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 500;
  border: solid 1px #E0E0E0;
}

.mypage .blog_setting .blog_set_wrap button.del_blog_article_btn {
  background: #F94545;
}

.mypage .blog_setting .blog_set_wrap button.add_blog_article_btn {
  background: #0F73EE;
  width: calc(100% - 80px);
  margin-left: auto;
}

.mypage .blog_setting .blog_set_wrap button.del_blog_btn {
  background: #F94545;
}

.mypage .blog_setting .blog_set_wrap button.add_blog_btn {
  background: #0F73EE;
}

.mypage .blog_setting .blog_set_wrap button.set_blog_btn {
  background: #343434;
  padding: 25px;
}

.mypage .blog_setting .blog_set_wrap .blog_set {
  padding-bottom: 30px;
  border-bottom: 1px solid #949494;
}

.mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set .ttl_style2 {
  font-size: 14px;
  margin-top: 20px;
}

.mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set .ttl_style2::before {
  height: 16px;
}

.mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set .ttl_style4 {
  font-size: 14px;
  position: relative;
  font-weight: 500;
  padding-left: 1em;
  margin-top: 20px;
  margin-bottom: 16px;
}

.mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set .ttl_style4::before {
  content: " ";
  position: absolute;
  width: 3px;
  height: 16px;
  background: #083CA9;
  left: 0.5em;
}

.mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set .blog_article_set {
  width: calc(100% - 40px);
  margin-left: auto;
  margin-top: 20px;
  padding: 15px 0;
  padding-left: 40px;
  border-left: solid 1px #ACACAC;
}

.mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set .blog_article_set .ttl_style4:nth-of-type(1) {
  margin-top: 0;
}

.mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set input {
  border-radius: 5px;
  border: solid 1px #E0E0E0;
  padding: 8px 15px;
}

.mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set input::-webkit-input-placeholder {
  color: #bbb;
}

.mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set input:-ms-input-placeholder {
  color: #bbb;
}

.mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set input::-ms-input-placeholder {
  color: #bbb;
}

.mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set input::placeholder {
  color: #bbb;
}

/*** 検索結果 ***/
.search_result .content_wrap {
  margin-top: 0;
}

.search_result .content_wrap .main_wrap {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin-left: 30px;
  margin-right: 0;
}

.search_result .content_wrap .main_wrap .content_inner .ttl {
  font-size: 28px;
  font-weight: bold;
  padding: 30px 10px 0;
  line-height: 1.46;
}

.search_result .content_wrap .main_wrap .content_inner .ttl span {
  margin-right: 0.25em;
}

.search_result .content_wrap .main_wrap .content_inner .ttl span.enterprise {
  display: block;
}

.search_result .content_wrap .main_wrap .content_inner .ttl span.freeword {
  display: block;
}

.search_result .content_wrap .main_wrap .content_inner .adv {
  margin-top: 30px;
  margin-bottom: 30px;
}

.search_result .content_wrap .main_wrap .content_inner .page_navi {
  padding: 0 10px 10px;
}

.search_result .content_wrap .main_wrap .content_inner .page_navi.pn_bottom {
  padding: 10px 10px 0;
}

.search_result .content_wrap .main_wrap .content_inner .page_navi .count_number {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: 10px;
}

.search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p a {
  color: #0F73EE;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  position: relative;
}

.search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.prev {
  margin-right: auto;
}

.search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.prev a {
  padding-left: 35px;
}

.search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.prev a::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 10px;
  width: 16px;
  height: 16px;
  background: #0F73EE;
}

.search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.prev a::after {
  content: '';
  width: 4px;
  height: 4px;
  margin-top: -1px;
  border-top: solid 2px #fff;
  border-left: solid 2px #fff;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  position: absolute;
  top: 50%;
  left: 16px;
}

.search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.next {
  margin-left: auto;
}

.search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.next a {
  padding-right: 35px;
}

.search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.next a::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 10px;
  width: 16px;
  height: 16px;
  background: #0F73EE;
}

.search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.next a::after {
  content: '';
  width: 4px;
  height: 4px;
  margin-top: -1px;
  border-top: solid 2px #fff;
  border-left: solid 2px #fff;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  position: absolute;
  top: 50%;
  right: 16px;
}

.search_result .content_wrap .main_wrap .content_inner ul {
  padding: 0 10px;
}

.search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout {
  position: relative;
}

.search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout:last-of-type {
  margin-bottom: 0;
}

.search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout .fav_button {
  width: 160px;
  font-size: 14px;
  position: absolute;
  padding: 10px;
  padding-right: 16px;
  padding-left: 40px;
  color: #707070;
  background: #fff;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  letter-spacing: 0.05em;
  -webkit-transition: .4s;
  transition: .4s;
  top: 16px;
  right: 16px;
  z-index: 1000;
}

.search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout .fav_button:hover {
  opacity: 1.0;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
}

.search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout .fav_button::before {
  content: "★";
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  font-size: 20px;
  -webkit-transition: .4s;
  transition: .4s;
}

.search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout .fav_button.on {
  background: #FFF941;
  color: #3A3939;
}

.search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout .fav_button.on::before {
  color: #0F73EE;
}

.search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout .card_lay_inner .ttl_wrap {
  width: calc(100% - 176px);
}

.search_result .content_wrap .sidenav .sidenav_inner {
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  margin-top: 30px;
}

/*** login ***/
.login .under_mv {
  background: #fff;
}

.login .under_mv .ttl h2 {
  font-size: 28px;
  font-weight: 700;
  margin-left: 40px;
}

.login .content_wrap {
  margin-top: 0;
}

.login .content_inner {
  width: 100%;
  padding: 60px;
  background: #F8FAFF;
  -webkit-box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.12);
          box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.12);
  margin-bottom: 30px;
  margin-top: 30px;
}

.login .content_inner ul {
  padding: 0 15px;
}

.login .content_inner ul li dl dt {
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
}

.login .content_inner ul li dl dd input {
  border-radius: 5px;
  border: solid 1px #0F73EE;
  padding: 8px 13px;
  width: 100%;
  letter-spacing: 0.2em;
}

.login .content_inner ul li dl dd input::-webkit-input-placeholder {
  color: #C9C9C9;
}

.login .content_inner ul li dl dd input:-ms-input-placeholder {
  color: #C9C9C9;
}

.login .content_inner ul li dl dd input::-ms-input-placeholder {
  color: #C9C9C9;
}

.login .content_inner ul li dl dd input::placeholder {
  color: #C9C9C9;
}

.login .content_inner ul li:nth-of-type(2) {
  margin-top: 20px;
  margin-bottom: 60px;
}

.login .content_inner .login_btn {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  background: #0F73EE;
  text-align: center;
  border-radius: 5px;
  border: solid 1px #083CA9;
  padding: 20px;
  width: 100%;
  letter-spacing: 0.2em;
}

/*** no_sidenav ***/
.wrapper.no_sidenav {
  min-height: 100vh;
  position: relative;
}

.wrapper.no_sidenav .under_mv {
  background: #fff;
}

.wrapper.no_sidenav .under_mv .ttl h1 {
  font-size: 28px;
  font-weight: 700;
  margin-left: 40px;
}

.wrapper.no_sidenav .content_wrap {
  margin-top: 0;
  padding-bottom: 50px;
}

.wrapper.no_sidenav .content_wrap .main_wrap {
  max-width: 100%;
  margin-right: 0;
  overflow: inherit;
}

.wrapper.no_sidenav .content_wrap .main_wrap .content_inner {
  background: #F8FAFF;
  padding: 40px;
  padding-bottom: 30px;
  -webkit-box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.25);
          box-shadow: 2px 2px 0 rgba(126, 142, 177, 0.25);
}

.wrapper.no_sidenav .content_wrap .main_wrap .content_inner li dl {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: solid 1px #DEDEDE;
}

.wrapper.no_sidenav .content_wrap .main_wrap .content_inner li dl dt {
  margin-bottom: 16px;
  line-height: 1.5;
}

.wrapper.no_sidenav .content_wrap .main_wrap .content_inner li dl dt::before {
  top: 4px;
}

.wrapper.no_sidenav .content_wrap .main_wrap .content_inner li dl dd {
  padding-left: 25px;
}

.wrapper.no_sidenav .content_wrap .main_wrap .content_inner li dl dd p {
  margin-bottom: 8px;
  line-height: 1.5;
}

.wrapper.no_sidenav .content_wrap .main_wrap .content_inner li dl dd p a {
  color: #0F73EE;
  border-bottom: solid 1px #0F73EE;
  font-size: 14px;
  display: inline-block;
}

.wrapper.no_sidenav .content_wrap .main_wrap .content_inner li:last-child dl {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.wrapper.no_sidenav .content_wrap .contact_wrap .contact_ttl {
  margin-top: 40px;
  margin-bottom: 30px;
}

.wrapper.no_sidenav .content_wrap .contact_wrap .contact_ttl h2 {
  font-size: 20px;
  display: block;
  border-bottom: 1px solid #0F73EE;
  text-align: center;
  width: 400px;
  margin: 0 auto;
  padding-bottom: 10px;
}

.wrapper.no_sidenav .content_wrap .contact_wrap .contact_btn {
  width: 100%;
  background: #0F73EE;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  padding: 20px;
  border: solid 1px #083CA9;
  text-align: center;
}

.wrapper.no_sidenav footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.not_found_menu {
  background: #f1f4fc;
  box-shadow: 0 4px 5px rgb(0 0 0 / 6%);
  padding: 15px 30px;
  align-items: center;
  width: 500px;
}

.notfound_inner {
  font-size: 20px;
  text-align: center;
  padding: 100px 0 40px;
}

.not_found_search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}

@media screen and (min-width: 769px) {
  h1 {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  /**** 共通パーツ ****/
  .sp_ib {
    display: inline-block;
  }
  .biginner {
    width: 100%;
    padding: 0 40px;
  }
  /***** header navi *****/
  #header .header_menu {
    padding: 15px 20px;
  }
  #header .header_menu .menu_wrap .search {
    margin-right: 10px;
  }
  #header .menu .dfb {
    display: block;
  }
  #header .menu .dfb li {
    border-bottom: 1px solid #70B0FF;
    border-right: none;
  }
  #header .menu .dfb li a {
    text-align: center;
    padding: 10px;
    font-size: 16px;
  }
  #header .menu .dfb li:last-child {
    border-bottom: none;
  }
  #header .header_search_box {
    overflow-y: scroll;
    display: 'none';
  }
  #header .header_search_box .search_box {
    position: inherit;
    top: 30px;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    left: 0;
    right: 0;
  }
  /*** search_box ***/
  .search_box .search_box_inner ul li.check_box ul li input {
    vertical-align: middle;
  }
  .search_box .search_box_inner ul li.radio_btn ul li input {
    vertical-align: middle;
  }
  .search_box .search_box_inner .search_btn {
    text-align: center;
    background: #0F73EE;
    border: solid 1px #083CA9;
    color: #fff;
    padding: 15px;
    font-weight: bold;
    width: 100%;
    border-radius: 5px;
    letter-spacing: 0.2em;
    font-size: 14px;
  }
  .search_box .search_close {
    text-align: right;
  }
  .search_box .search_close img {
    width: 40px;
    margin-top: 10px;
    cursor: pointer;
    display: inline-block;
  }
  /*** パンくずリスト ***/
  .breadcrumbs {
    padding: 5px 20px;
  }
  .breadcrumbs p {
    line-height: 1.4;
  }
  /****　タブ切り替え　****/
  .tab_biginner {
    margin-top: 20px;
  }
  .tab_biginner .tab-area .btn_wrap button {
    width: 40px;
    height: 40px;
  }
  .tab_biginner .tab-area .btn_wrap button.left::before {
    left: 16px;
  }
  .tab_biginner .tab-area .btn_wrap button.right::before {
    right: 16px;
  }
  .tab_biginner .tab-area .tab_wrap {
    width: calc(100% - 90px);
  }
  .tab_biginner .tab-area .tab_wrap .tab_inner .tab {
    font-size: 16px;
    padding: 12px 15px;
  }
  .tab_biginner .tab-area .tab_wrap .tab_inner .tab.active {
    background-color: #0F73EE;
    color: #fff;
  }
  /**** tab切り替えコンテンツ ****/
  .content-area .content {
    padding: 0 20px;
  }
  .content-area .content .ttl_style3 {
    font-size: 20px;
    padding: 8px;
    margin-bottom: 10px;
  }
  .content-area .content .adv {
    margin-bottom: 20px;
  }
  .content-area .content .content_inner {
    padding: 0;
    margin-bottom: 20px;
  }
  .content-area .content .content_inner.border_bottom {
    padding-bottom: 20px;
  }
  .content-area .content .content_inner .slide_movie.dfb {
    display: block;
  }
  .content-area .content .content_inner .slide_movie .slide {
    margin-bottom: 20px;
  }
  .content-area .content .content_inner .slide_movie .slide .thumnail.dfb li {
    width: 100%;
    margin-right: 5px;
  }
  .content-area .content .content_inner .slide_movie .slide .thumnail.dfb li:last-child {
    margin-right: 0;
  }
  .content-area .content .content_inner .slide_movie .movie {
    max-width: inherit;
    height: 280px;
    margin-left: 0;
  }
  .content-area .content .content_inner .info_table_layout li .dpt {
    width: 100%;
    display: block;
  }
  .content-area .content .content_inner .info_table_layout li .dpt dt, .content-area .content .content_inner .info_table_layout li .dpt dd {
    display: block;
    border: solid 1px #E0E0E0;
    border-bottom: none;
  }
  .content-area .content .content_inner .info_table_layout li .dpt dt {
    width: 100%;
  }
  .content-area .content .content_inner .info_table_layout li .dpt dd {
    width: 100%;
    border-left: solid 1px #E0E0E0;
  }
  .content-area .content .content_inner .info_table_layout li .dpt dd a {
    color: #0F73EE;
  }
  .content-area .content .content_inner .info_table_layout li .dpt dd a.gmap {
    margin-top: 8px;
  }
  .content-area .content .content_inner .info_table_layout li .dpt dd a.homepage {
    display: inline;
    word-break: break-all;
  }
  .content-area .content .content_inner .info_table_layout li:first-child dt {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .content-area .content .content_inner .info_table_layout li:first-child dd {
    border-top-right-radius: 0px;
  }
  .content-area .content .content_inner .info_table_layout li:last-child dt {
    border-bottom-left-radius: 0px;
    border-bottom: none;
  }
  .content-area .content .content_inner .info_table_layout li:last-child dd {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom: solid 1px #E0E0E0;
  }
  .content-area .content .content_inner .pr {
    padding: 16px;
  }
  .content-area .content .content_inner .pr h3 {
    margin-bottom: 10px;
    padding-left: 0.5em;
  }
  .content-area .content .content_inner .pr h3.ttl_style2::before {
    left: 0;
  }
  .content-area .content .content_inner .pr p {
    margin-left: 0.7em;
  }
  .content-area .content.blog_info .blog_list_wrap .blog_list {
    margin-bottom: 20px;
  }
  .content-area .content.blog_info .blog_list_wrap .blog_list h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .content-area .content.blog_info .blog_list_wrap .blog_list .blog_link {
    margin-bottom: 10px;
  }
  .content-area .content.blog_info .blog_list_wrap .blog_list .blog_link img {
    width: 20px;
  }
  .content-area .content.blog_info .blog_list_wrap .blog_list .blog_article_wrap .blog_article_list .blog_article_link {
    margin-bottom: 10px;
  }
  .content-area .content.blog_info .blog_list_wrap .blog_list .blog_article_wrap .blog_article_list .blog_article_link h4 {
    margin-bottom: 5px;
  }
  .content-area .content.show {
    display: block;
  }
  /** contents **/
  .content_wrap {
    display: block;
    margin-top: 20px;
  }
  .content_wrap .main_wrap {
    margin-right: 0;
  }
  .content_wrap .sidenav {
    width: calc(100% - 40px);
    margin: 30px auto 0;
    max-width: inherit;
  }
  /*** footer ***/
  footer {
    padding: 10px 20px 20px;
  }
  footer ul.dfb {
    display: block;
  }
  footer ul.dfb li {
    border-left: none;
    text-align: center;
    border-bottom: 1px solid #70B0FF;
    margin-left: 0;
  }
  footer ul.dfb li a {
    padding: 10px;
    text-align: center;
  }
  footer ul.dfb li.copy {
    padding: 10px;
    padding-bottom: 0;
  }
  /**** corp_card_layout ****/
  .corp_card_layout {
    margin-bottom: 15px;
  }
  /*** top ***/
  .top .content_wrap {
    margin-top: 0;
  }
  .top .top_mv {
    padding-top: 100px;
    padding-bottom: 100px;
    background: url(../img/mv_sp.jpg) no-repeat bottom center;
    background-size: cover;
  }
  .top .top_mv .biginner {
    padding: 0 20px;
  }
  .top .top_mv .ttl_style {
    font-size: 24px;
  }
  .top .top_mv .top_search_box {
    width: 100%;
    background: #F8FAFF;
  }
  .top .top_mv .top_search_box .top_txt {
    padding-left: 20px;
    padding-right: 0;
    width: calc(100% - 142px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .top .top_mv .top_search_box .top_select {
    width: 92px;
    padding-right: 5px;
    padding-left: 8px;
  }
  .top .top_mv .top_search_box .top_search_btn {
    padding: 0 20px 0 13px;
    width: 50px;
  }
  .top .top_mv .mvtxt {
    letter-spacing: 0.05em;
    margin-top: 8px;
    width: 300px;
    padding: 0;
    padding-bottom: 2px;
  }
  .top .top_search {
    padding: 20px;
    padding-bottom: 10px;
  }
  .top .top_search h2 .sp_ib {
    position: relative;
    width: 9px;
    margin-left: 10px;
    -webkit-transition: .3s;
    transition: .3s;
  }
  .top .top_search h2 .sp_ib::before {
    position: absolute;
    content: " ";
    width: 3px;
    height: 10px;
    background: #222;
    top: -10px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: .3s;
    transition: .3s;
  }
  .top .top_search h2 .sp_ib::after {
    position: absolute;
    content: " ";
    width: 3px;
    height: 10px;
    background: #222;
    top: -10px;
    right: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: .3s;
    transition: .3s;
  }
  .top .top_search.open h2 .sp_ib::before {
    top: -12px;
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  .top .top_search.open h2 .sp_ib::after {
    top: -12px;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  .top .top_corp_card {
    width: calc(100% - 40px);
    margin: 0 auto 30px;
  }
  .top .top_corp_card .dfb li {
    width: 100%;
    max-width: inherit;
  }
  .top .top_corp_card .dfb li:nth-child(2n+1) {
    margin-right: 0;
  }
  .top .top_corp_card .dfb li:last-child {
    margin-bottom: 0;
  }
  /****　corporate　****/
  .under_mv {
    background: #F8FAFF;
    padding: 20px;
  }
  .under_mv .biginner {
    padding: 0;
  }
  .corporate .ttl h1 {
    font-size: 20px;
    line-height: 1.2;
    width: calc(100% - 70px);
    margin-right: 30px;
  }
  .corporate .ttl .fav_button {
    width: 40px;
    height: 40px;
    padding: 0;
  }
  .corporate .ttl .fav_button::before {
    content: "★";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 20px;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .corporate .icon_wrap {
    margin-top: 10px;
  }
  .corporate .icon_wrap p {
    color: #666666;
    font-size: 14px;
  }
  .corporate .icon_wrap p.add {
    margin-bottom: 3px;
  }
  .corporate .icon_wrap p img {
    width: 10px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: sub;
  }
  .corporate .info {
    margin-top: 12px;
  }
  .corporate .info dl:last-child {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .corporate .info dl:last-child dt {
    margin-bottom: 8px;
  }
  .corporate .info dl:last-child dd {
    width: 100%;
  }
  .corporate .info dl dt {
    border-radius: 5px;
    background: #083CA9;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    padding: 3px 0 5px;
    margin-right: 11px;
    letter-spacing: 0.1em;
  }
  .corporate .info dl dd {
    font-size: 14px;
    line-height: 1.3;
  }
  /**** mypage ****/
  .mypage .ttl h2 {
    font-size: 20px;
    font-weight: 700;
  }
  .mypage .ttl h3 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    line-height: 1.5;
  }
  .mypage .blog_setting .statement {
    font-size: 12px;
    line-height: 1.5;
  }
  .mypage .blog_setting .blog_set_wrap {
    margin-top: 20px;
  }
  .mypage .blog_setting .blog_set_wrap button.add_blog_article_btn {
    width: calc(100% - 40px);
  }
  .mypage .blog_setting .blog_set_wrap .blog_set {
    padding-bottom: 30px;
    border-bottom: 1px solid #949494;
  }
  .mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set .ttl_style2 {
    margin-bottom: 10px;
  }
  .mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set .ttl_style4 {
    margin-bottom: 10px;
  }
  .mypage .blog_setting .blog_set_wrap .blog_set .blog_ttl_set .blog_article_set {
    width: calc(100% - 20px);
    padding-left: 20px;
  }
  /*** 検索結果 ***/
  .search_result .content_wrap {
    margin-top: 0;
  }
  .search_result .content_wrap .main_wrap {
    margin-left: 0;
    margin-right: 0;
  }
  .search_result .content_wrap .main_wrap .content_inner {
    padding: 0 20px;
  }
  .search_result .content_wrap .main_wrap .content_inner .ttl {
    font-size: 20px;
    padding-top: 20px;
    padding-left: 0;
  }
  .search_result .content_wrap .main_wrap .content_inner .adv {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .search_result .content_wrap .main_wrap .content_inner .adv:last-of-type {
    margin-bottom: 0;
  }
  .search_result .content_wrap .main_wrap .content_inner .page_navi {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  .search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.prev a {
    padding-left: 25px;
  }
  .search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.prev a::before {
    left: 0;
  }
  .search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.prev a::after {
    left: 6px;
  }
  .search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.next a {
    padding-right: 25px;
  }
  .search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.next a::before {
    right: 0px;
  }
  .search_result .content_wrap .main_wrap .content_inner .page_navi .dfb p.next a::after {
    right: 6px;
  }
  .search_result .content_wrap .main_wrap .content_inner ul {
    padding: 0;
  }
  .search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout {
    position: relative;
  }
  .search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout:last-of-type {
    margin-bottom: 0;
  }
  .search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout .fav_button {
    width: 40px;
    height: 40px;
    padding: 0;
  }
  .search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout .fav_button::before {
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .search_result .content_wrap .main_wrap .content_inner ul .corp_card_layout .card_lay_inner .ttl_wrap {
    width: calc(100% - 56px);
  }
  .search_result .content_wrap .sidenav .sidenav_inner {
    -webkit-box-shadow: none;
            box-shadow: none;
    padding: 0;
    margin-top: 30px;
  }
  /*** login ***/
  .login .under_mv .ttl h2 {
    font-size: 20px;
    margin-left: 0;
  }
  .login .content_wrap .main_wrap {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
  .login .content_inner {
    padding: 10px 16px 16px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .login .content_inner ul {
    padding: 0;
  }
  .login .content_inner ul li dl dt {
    padding-left: 0;
  }
  .login .content_inner ul li:nth-of-type(2) {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  /*** exhibit ***/
  .wrapper.no_sidenav .under_mv .ttl h1 {
    font-size: 20px;
    margin-left: 0;
  }
  .wrapper.no_sidenav .content_wrap .main_wrap {
    padding: 0 20px;
  }
  .wrapper.no_sidenav .content_wrap .main_wrap .content_inner {
    padding: 16px;
    padding-bottom: 10px;
  }
  .wrapper.no_sidenav .content_wrap .main_wrap .content_inner li dl {
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
  .wrapper.no_sidenav .content_wrap .main_wrap .content_inner li dl dt {
    font-size: 14px;
    margin-bottom: 8px;
    padding-left: 1em;
  }
  .wrapper.no_sidenav .content_wrap .main_wrap .content_inner li dl dt::before {
    left: 5px;
    top: 1px;
  }
  .wrapper.no_sidenav .content_wrap .main_wrap .content_inner li dl dd {
    padding-left: 14px;
    font-size: 12px;
  }
  .wrapper.no_sidenav .content_wrap .main_wrap .content_inner li dl dd p {
    margin-bottom: 8px;
  }
  .wrapper.no_sidenav .content_wrap .contact_wrap .contact_ttl {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .wrapper.no_sidenav .content_wrap .contact_wrap .contact_ttl h2 {
    font-size: 14px;
    width: 100%;
    padding-bottom: 10px;
  }
  .wrapper.no_sidenav .content_wrap .contact_wrap .contact_btn {
    font-size: 12px;
  }
  .privacy_policy.no_sidenav .content_wrap {
    padding-bottom: 0;
  }
  .privacy_policy.no_sidenav footer {
    position: inherit;
  }
}
/*# sourceMappingURL=common.css.map */


/*HOMEにインボイスのチェックボックスを追加 (2023-12-26)*/
.inblock{display: inline-block;} /*折り返しを制御*/
.top .top_mv .top_check_box {
  -webkit-justify-content: center;
  justify-content: center;
  align-items: start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 18px auto 10px;
  font-size: 16px;
}
.side_check_box{
  display: flex;
  justify-content: center;
  align-items: start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
}
.side_check_box input{
  display: inline;
  width: inherit!important;
  vertical-align: initial;
}
.top_check_box label,
.side_check_box label {
  font-size: 15px;
  padding-left: 2px;
  line-height: 1.4;
  margin-top: -2px;
}
/*HOMEのインボイスのチェックボックスを変更 (2023-12-27)*/
.top_check_box > input[type="checkbox"]  {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* チェックボックスデザイン */
.top_check_box > input[type="checkbox"] {
  cursor: pointer;
  padding-left: 25px;
  vertical-align: middle;
  position: relative;
}
.top_check_box > input[type="checkbox"]::before,
.top_check_box > input[type="checkbox"]::after {
  content: "";
  display: block; 
  position: absolute;
}
.top_check_box > input[type="checkbox"]::before {
  background-color: #fff;
  border-radius: 3px;
  border: 2px solid #666464;
  width: 13px;
  height: 13px;
  transform: translateY(-50%);
  top: 12px;
  left: 4px;
}
.top_check_box > input[type="checkbox"]::after {
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  opacity: 0;
  height: 4px;
  width: 6px;
  transform: rotate(-45deg);
  top: 7px;
  left: 9px;
}
.top_check_box > input[type="checkbox"]:checked::before {
  border: solid 2px #0F73EE;
  background: #0F73EE;;
}
.top_check_box > input[type="checkbox"]:checked::after {
  opacity: 1;
  
}
/*設立・倒産企業 タブ追加　2024/04/03*/
.search_box .search_box_inner ul li p.note{
  font-size: 12px;
  text-align: right;
  margin: 10px 0 0;
}
.side_tab > div.side_tab_inner{ 
  padding: 1.5em 0 0;
}
.side_tab {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
}

.side_tab > label {
  flex: 1 1;
  order: -1;
  position: relative;
  min-width: 70px;
  padding: .7em 1em;
  font-size: .9em;
  text-align: center;
  cursor: pointer;
  border: solid 1px #0F73EE;
  margin-right: 2px;
}

.side_tab > label:hover,
.side_tab label:has(:checked) {
  background-color: #0f73ee;
  color: #fff;
}

.side_tab label:has(:checked)::before {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 18px;
  height: 9px;
  background-color: #0f73ee;
  content: '';
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}

.side_tab input {
  display: none;
}

.side_tab > div {
  display: none;
  width: 100%;
  padding: 1.5em 1em;
}

.side_tab label:has(:checked) + div {
  display: block;
}